import { render, staticRenderFns } from "./EditDialogSelect.vue?vue&type=template&id=1cb271dd&scoped=true&"
import script from "./EditDialogSelect.vue?vue&type=script&lang=js&"
export * from "./EditDialogSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cb271dd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VEditDialog } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VEditDialog,VSelect})
