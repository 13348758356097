<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card
      v-if="registered"
      flat
    >
      <h1>Registrierung abgeschlossen</h1>
      <p>Vielen Dank für Ihre Registrierung</p>
      <p>Sie erhalten eine E-Mail mit einem Link zur Bestätigung der Registrierung.</p>
      <p style="font-style: italic;">Sollten Sie keine E-Mail erhalten, so sehen Sie bitte auch im Spam-Ordner nach!</p>
    </v-card>
    <v-card
      v-else
      flat
    >
      <h1>Registrierung</h1>
      <p>Hier können Sie sich kostenlos für das System registrieren.</p>
      <p>Die erste Person, die eine Schule/einen Verein anlegt übernimmt die Funktion des Administrators für die jeweilige Organisation. Sollten sich weitere Personen für die Organisation anmelden, muss der Administrator diese innerhalb des Tools bestätigen.</p>
      <p>Anmerkung: School-in-Motion/4INMOTION ist in erster Linie für Schulen konzipiert. Wird eine Registrierung missbräuchlich verwendet, so behalten wir uns vor, den Nutzer zu sperren bzw. zu löschen.</p>
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="plz"
            label="Postleitzahl"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-select
            v-model="schule"
            :items="organizer"
            label="Bitte Schule/Verein auswählen"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-if="schule && schule !== -1"
            :value="schule.name"
            label="Schulname/Vereinsname"
            disabled
          />
          <v-text-field
            v-else
            v-model="name"
            label="Schulname/Vereinsname"
            :disabled="schule !== -1"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-if="schule && schule !== -1"
            :value="schule.ort"
            label="Ort/Stadt"
            disabled
          />
          <v-text-field
            v-else
            v-model="ort"
            label="Ort/Stadt"
            :disabled="schule !== -1"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="givenName"
            label="Vorname"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="familyName"
            label="Nachname"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="email"
            label="E-Mail-Adresse"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="password"
            type="password"
            label="Passwort"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="password2"
            type="password"
            label="Passwort wiederholen"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-btn
            block
            @click="register"
          >
            Registrieren
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'PagesRegister',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  data: () => ({
    plz: null,
    ort: null,
    name: null,
    givenName: null,
    familyName: null,
    email: null,
    password: null,
    password2: null,
    schule: null,
    OrganizerFind: [],
    registered: false
  }),

  computed: {
    organizer () {
      return [{ value: null, text: 'bitte Schule auswählen...', disabled: true }, ...this.OrganizerFind.map(o => ({ value: o, text: o.name })), { value: -1, text: 'neue Schule anlegen' }]
    }
  },

  methods: {
    async register () {
      const err = []
      const EMAIL_ADDRESS_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

      if (!this.plz) err.push('Postleitzahl fehlt!')
      if (isNaN(parseInt(this.plz))) err.push('Postleitzahl darf nur aus Ziffern bestehen!')
      if (!this.schule) err.push('Schule fehlt!')
      if (this.schule === -1 && !this.name) err.push('Schulname fehlt!')
      if (this.schule === -1 && !this.ort) err.push('Schulort fehlt!')
      if (!this.givenName) err.push('Vorname fehlt!')
      if (!this.familyName) err.push('Nachname fehlt!')
      if (!this.email) err.push('E-Mail-Adresse fehlt!')
      if (this.email && !EMAIL_ADDRESS_REGEX.test(this.email)) err.push('E-Mail-Adresse ungültig!')
      if (!this.password) err.push('Passwort fehlt!')
      if (this.password !== this.password2) err.push('Passwörter stimmen nicht überein!')

      if (err.length !== 0) {
        this.$store.commit('OPEN_SNACKBAR', `<ul><li>${err.join('</li><li>')}</li></ul>`)
        return
      }

      let schulid = null

      if (this.schule === -1) {
        const tmp = await this.$apollo.mutate({
          mutation: gql`mutation($name: String!, $plz: Int!, $ort: String!) {
            OrganizerRegister(name: $name, plz: $plz, ort: $ort) { _id }
          }`,
          variables: {
            name: this.name,
            plz: parseInt(this.plz),
            ort: this.ort
          }
        })
        schulid = tmp.data.OrganizerRegister._id
      } else {
        schulid = this.schule._id
      }

      await this.$apollo.mutate({
        mutation: gql`mutation($organizer: UUID!, $givenName: String!, $familyName: String!, $email: EmailAddress!, $passwort: String!) {
          PersonRegister(organizer: $organizer, givenName: $givenName, familyName: $familyName, email: $email, passwort: $passwort) { _id }
        }`,
        variables: {
          organizer: schulid,
          givenName: this.givenName,
          familyName: this.familyName,
          email: this.email,
          passwort: this.password
        }
      })

      this.registered = true
    }
  },

  watch: {
    plz (v) {
      const tmp = parseInt(v)
      if (isNaN(tmp)) return

      this.$apollo.query({
        query: gql`query($plz: Int!) { OrganizerFind(plz: $plz) { _id name plz ort } }`,
        variables: {
          plz: tmp
        }
      }).then((r) => {
        this.OrganizerFind = r.data.OrganizerFind
      })
    }
  }
}
</script>
