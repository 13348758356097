<template>
  <v-edit-dialog
    :return-value.sync="wert"
    large
    persistent
    :save-text="savebutton"
    :cancel-text="cancelbutton"
    @open="open"
    @save="save"
    @close="close"
  >
    <div style="min-width:40px;">
      {{ value }}
    </div>
    <template #input>
      <v-text-field
        v-model="wert"
        :label="label"
        autofocus
      />
    </template>
  </v-edit-dialog>
</template>

<script>
export default {
  name: 'EditDialog',

  props: {
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    savebutton: {
      type: String,
      required: false,
      default: 'Übernehmen'
    },
    cancelbutton: {
      type: String,
      required: false,
      default: 'Abbrechen'
    }
  },

  data: () => ({
    wert: null
  }),

  methods: {
    open () {
      if (this.value === null || this.value === undefined) {
        this.wert = ''
      } else {
        this.wert = `${this.value}`
      }
    },
    close () {
      this.wert = null
    },
    save () {
      this.$emit('input', this.wert)
      this.wert = null
    }
  }
}
</script>

<style scoped>

</style>
