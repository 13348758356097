<template>
  <e403 v-if="!isAdmin(id)" />
  <v-container
    v-else
    fluid
    tag="section"
  >
    <h2>Administratoren {{ Organizer.name }}</h2>
    <v-data-table
      :items="Organizer.admins"
      :headers="[
        { text: 'Nachname', value: 'familyName' },
        { text: 'Vorname', value: 'givenName' },
        { text: 'zum Organisator zurückstufen', value: 'makeOrga' },
        { text: 'löschen', value: 'delete' }
      ]"
      :items-per-page="-1"
      :mobile-breakpoint="0"
    >
      <template #item.makeOrga="{item}">
        <v-btn
          v-if="item._id !== $store.state.profile._id"
          fab
          small
          @click="makeOrga(item._id)"
        >
          <v-icon>far fa-angle-down</v-icon>
        </v-btn>
      </template>
      <template #item.delete="{item}">
        <v-btn
          v-if="item._id !== $store.state.profile._id"
          fab
          small
          @click="del(item._id)"
        >
          <v-icon>far fa-trash-alt</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <h2>Organisatoren {{ Organizer.name }}</h2>
    <v-data-table
      :items="Organizer.organizers"
      :headers="[
        { text: 'Nachname', value: 'familyName' },
        { text: 'Vorname', value: 'givenName' },
        { text: 'zum Admin hochstufen', value: 'makeAdmin' },
        { text: 'löschen', value: 'delete' }
      ]"
      :items-per-page="-1"
      :mobile-breakpoint="0"
    >
      <template #item.makeAdmin="{item}">
        <v-btn
          v-if="item._id !== $store.state.profile._id"
          fab
          small
          @click="makeAdmin(item._id)"
        >
          <v-icon>far fa-angle-up</v-icon>
        </v-btn>
      </template>
      <template #item.delete="{item}">
        <v-btn
          v-if="item._id !== $store.state.profile._id"
          fab
          small
          @click="del(item._id)"
        >
          <v-icon>far fa-trash-alt</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <h2>Ausstehende Registrierungen {{ Organizer.name }}</h2>
    <v-data-table
      :items="Organizer.pending"
      :headers="[
        { text: 'Nachname', value: 'familyName' },
        { text: 'Vorname', value: 'givenName' },
        { text: 'als Organisator hinzufügen', value: 'makeOrga' },
        { text: 'als Admin hinzufügen', value: 'makeAdmin' },
        { text: 'löschen', value: 'delete' }
      ]"
      :items-per-page="-1"
      :mobile-breakpoint="0"
    >
      <template #item.makeOrga="{item}">
        <v-btn
          v-if="item._id !== $store.state.profile._id"
          fab
          small
          @click="makeOrga(item._id)"
        >
          <v-icon>far fa-angle-up</v-icon>
        </v-btn>
      </template>
      <template #item.makeAdmin="{item}">
        <v-btn
          v-if="item._id !== $store.state.profile._id"
          fab
          small
          @click="makeAdmin(item._id)"
        >
          <v-icon>far fa-angle-double-up</v-icon>
        </v-btn>
      </template>
      <template #item.delete="{item}">
        <v-btn
          v-if="item._id !== $store.state.profile._id"
          fab
          small
          @click="del(item._id)"
        >
          <v-icon>far fa-trash-alt</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { useAuth } from '@/plugins/auth'
import gql from 'graphql-tag'

const query = `
  _id name
  admins { _id givenName familyName }
  organizers { _id givenName familyName }
  pending { _id givenName familyName }
`

export default {
  name: 'school',

  setup (props, context) {
    return {
      ...useAuth(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    Organizer: {},
    EventFind: [],
    filter: ''
  }),

  methods: {
    makeOrga (p) {
      this.$apollo.mutate({
        mutation: gql`mutation($id: UUID!, $person: UUID!) {
          OrganizerUpdateMakeOrganizer(id: $id, person: $person) { _id }
        }`,
        variables: {
          id: this.id,
          person: p
        }
      })
    },
    makeAdmin (p) {
      this.$apollo.mutate({
        mutation: gql`mutation($id: UUID!, $person: UUID!) {
          OrganizerUpdateMakeAdmin(id: $id, person: $person) { _id }
        }`,
        variables: {
          id: this.id,
          person: p
        }
      })
    },
    async del (p) {
      if (await this.$root.$children[0].$refs.confirm.open('Löschen', 'Diese Person wirklich aus Ihrer Schule löschen?')) {
        this.$apollo.mutate({
          mutation: gql`mutation($id: UUID!, $person: UUID!) {
          OrganizerUpdateDeletePerson(id: $id, person: $person) { _id }
        }`,
          variables: {
            id: this.id,
            person: p
          }
        })
      }
    }
  },

  apollo: {
    Organizer: {
      query: gql`query($organizer: UUID!) {
        Organizer(id: $organizer) { ${query} }
      }`,
      variables () {
        return {
          organizer: this.id
        }
      }
    },
    $subscribe: {
      OrganizerUpdated: {
        query: gql`subscription($organizer: UUID!) { OrganizerUpdated(organizer: $organizer) { ${query} } }`,
        variables () {
          return {
            organizer: this.id
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
