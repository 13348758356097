<template>
  <v-container
    fluid
    tag="section"
  >
    <h1>4INMOTION</h1>
    <img src="../../assets/logo_4im.jpg" />
    <h2><b>4<span style="font-weight:400;">IN</span>MOTION</b> ist ein Mannschaftswettbewerb, bei dem eine Anzahl von zwei bis vier Personen in einem Team antreten. Es ist dabei unerheblich, ob das Team rein männlich, rein weiblich oder als gemischtes Team zusammengestellt wird.</h2>
    <h3>Ziel des Wettbewerbs ist es, eine möglichst schnelle Zeit in einem Geräte-Parcours zu erzielen. Zusätzlich sind an den einzelnen Geräten turnerische Elemente zu erfüllen.</h3>
    <h3>Der Parcours kann sich aus den unterschiedlichsten Turngeräten zusammensetzen, es sind hierbei vier Geräte zu verwenden. An jedem Gerät müssen fünf Elemente aus der Elementeliste gezeigt werden. Jedes Teammitglied kann ein oder zwei Geräte turnen, allerdings nicht direkt hintereinander. Es zählt die Team-Zeit, nicht die beste Ausführung! Allerdings könnt ihr euch mit einer guten Ausführung einen kleinen Zeitbonus erturnen. So oder so garantiert 4INMOTION Spaß und Action beim gemeinsamen Turnen.</h3>
    <h2>Vergangene Events:</h2>
    <v-data-table
      :headers="[
        { text: 'Datum', value: 'date', sortable: false },
        { text: 'Name', value: 'name', sortable: false }
      ]"
      :items="e"
      @click:row="open"
    />
  </v-container>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'start_4im',

  data: () => ({
    EventFind: []
  }),

  computed: {
    e () {
      return this.EventFind.filter(e => new Date(e.date).getTime() <= new Date().getTime() + 3600 * 1000 * 24 * 7).sort((a, b) => a.date < b.date ? 1 : -1)
    }
  },

  methods: {
    open (item) {
      this.$router.push(`/e/${item._id}`)
    }
  },

  apollo: {
    EventFind: {
      query: gql`query { EventFind { _id name date } }`
    }
  }
}
</script>

<style scoped>
img {
  display: block;
  max-width: calc(100% - 48px);
  width: 400px;
  height: auto;
  /* float: right; */
  margin: 24px auto;
}

h1 {
  font-size: 350%;
  text-align: center;
}

h2 {
  line-height: 180%;
  margin-bottom: 16px;
  letter-spacing: 0.2px;
}

h3 {
  line-height: 180%;
  letter-spacing: 0.2px;
  margin-bottom: 12px;
}
</style>
