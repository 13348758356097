<template>
  <v-app>
    <dashboard-core-app-bar v-model="expandOnHover" />

    <v-progress-linear
      :active="loading"
      indeterminate
      absolute
      color="red"
    />

    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />

    <dashboard-core-view />
  </v-app>
</template>

<script>
import { generateUrkunde } from '@/urkunde'

export default {
  name: 'DashboardIndex',

  components: {
    DashboardCoreAppBar: () => import('./components/core/AppBar'),
    DashboardCoreDrawer: () => import('./components/core/Drawer'),
    DashboardCoreView: () => import('./components/core/View')
  },

  data: () => ({
    expandOnHover: false,
    image: null
  }),

  computed: {
    loading () {
      return false // this.$store.state.loading
    }
  },

  methods: {
    async generatePDF () {
      generateUrkunde([{ platz: '1. PLATZ', zeit: '0 MIN. 23 SEK.', team: 'Tolles Team' }, { platz: '2. PLATZ', zeit: '0 MIN. 24 SEK.', team: 'Fast Tolles Team' }, { platz: '3. PLATZ', zeit: '5 MIN. 24 SEK.', team: 'Schlechtes Team' }], 'Droste', 'Alexander Kimmig', this.image, true)
    }
  }
}
</script>
