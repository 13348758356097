<template>
  <base-material-dialog
    :value="value"
    icon="far fa-pencil"
    title="Gerät bearbeiten"
    color="rgb(255, 4, 29)"
    :actions="['save', 'cancel']"
    @save="save"
    @close="close"
    @esc="close"
  >
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="e"
          label="Elemente"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="b"
          label="Bonus"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="m"
          label="Malus"
        />
      </v-col>
    </v-row>
  </base-material-dialog>
</template>

<script>
export default {
  name: 'EditEventapparatus',

  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    elements: {
      type: Number,
      default: 3
    },
    bonus: {
      type: Number,
      default: 3.00
    },
    malus: {
      type: Number,
      default: 5.00
    }
  },

  data: () => ({
    e: '',
    b: '',
    m: ''
  }),

  methods: {
    save () {
      this.$emit('save', { id: this.id, elements: parseInt(this.e), bonus: parseFloat(this.b.replaceAll(',', '.')), malus: parseFloat(this.m.replaceAll(',', '.')) })
      this.close()
    },
    close () {
      this.$emit('input', false)
    }
  },

  watch: {
    value () {
      if (this.value) {
        this.e = `${this.elements}`
        this.b = `${this.bonus}`
        this.m = `${this.malus}`
      }
    }
  }
}
</script>

<style scoped>

</style>
