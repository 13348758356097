<template>
  <e403 v-if="!isMaster" />
  <v-container
    v-else
    fluid
    tag="section"
  >
    <v-card
      flat
    >
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="open(null)"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>

      <v-col>
        <v-text-field
          v-model="filter"
          label="Filter"
        />
      </v-col>

      <v-data-table
        :headers="headers"
        :items="PersonFind"
        sort-by="familyName"
        :items-per-page="15"
        mobile-breakpoint="0"
        :search="filter"
        @click:row="open"
      />
    </v-card>
    <edit-person
      :id="dialog.id"
      v-model="dialog.open"
    />
  </v-container>
</template>

<script>
import { useAuth } from '@/plugins/auth'
import gql from 'graphql-tag'
import { updateQuery, deleteQuery } from '@/plugins/graphql'

export default {
  name: 'People',

  components: {
    EditPerson: () => import('./dialogs/EditPerson')
  },

  setup (props, context) {
    return {
      ...useAuth(context)
    }
  },

  data: () => ({
    PersonFind: [],
    headers: [
      {
        text: 'Nachname',
        value: 'familyName',
        sortable: true
      },
      {
        text: 'Vorname',
        value: 'givenName',
        sortable: true
      }
    ],
    dialog: {
      open: false,
      id: null
    },
    filter: ''
  }),

  apollo: {
    PersonFind: {
      query: gql`query { PersonFind { _id familyName givenName adminOf { name plz ort } organizerOf { name plz ort } } }`,
      subscribeToMore: {
        document: gql`subscription { PersonUpdated { _id familyName givenName adminOf { name plz ort } organizerOf { name plz ort } } }`,
        updateQuery: updateQuery('PersonFind', 'PersonUpdated')
      }
    },
    $subscribe: {
      PersonDeleted: {
        query: gql`subscription { PersonDeleted }`,
        result (id) {
          deleteQuery('PersonFind', 'PersonDeleted', this.PersonFind, id)
        }
      }
    }
  },

  methods: {
    open (item) {
      this.dialog.open = true
      this.dialog.id = item?._id
    }
  }
}
</script>

<style scoped>
</style>
