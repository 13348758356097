<template>
  <div>
    <base-item
      :item="{
        icon:'mdi-view-dashboard',
        title: 'Startseite',
        to: '/'
      }"
    />
    <base-item-group
      v-if="isMaster"
      :item="{
        group: '/management',
        icon: 'fa-user-crown',
        title: 'Administration',
        children: [
          {
            title: 'Geräte',
            to: 'apparatus',
            icon: 'fa-dumbbell',
          },
          {
            title: 'Veranstalter',
            to: 'organizer',
            icon: 'mdi-account-supervisor-circle',
          },
          {
            title: 'Personen',
            to: 'person',
            icon: 'mdi-account-edit',
          }
        ]
      }"
    />
    <base-item
      v-for="a in adminOf"
      :key="'admin-'+a._id"
      :item="{
        // group: '/admin',
        icon: 'fa-user-crown',
        title: 'Verwaltung',
        subtitle: a.name,
        to: '/admin/' + a._id
        /* children: [
          {
            title: 'Nutzer verwalten',
            icon: 'fa-users',
            to: 'person'
          }
        ] */
      }"
    />
    <base-item
      v-for="o in organizerOf"
      :key="'orga-'+o._id"
      :item="{
        // group: '/orga',
        icon: 'fa-calendar-edit',
        title: 'Organisation',
        subtitle: o.name,
        to: '/orga/' + o._id
        /* children: [
          {
            title: 'Events verwalten',
            icon: 'fa-calendar-day',
            to: 'event/' + o._id
          }
        ] */
      }"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Menu',

  computed: {
    ...mapGetters(['profile', 'isMaster']),
    adminOf () {
      return this.profile?.adminOf
    },
    organizerOf () {
      return [...(this.profile?.adminOf || []), ...(this.profile?.organizerOf || [])]
    }
  }
}
</script>

<style scoped>

</style>
