<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <base-material-card
        color="red"
        light
        max-width="100%"
        width="400"
        class="px-5 py-3"
      >
        <template #heading>
          <div class="text-center">
            <h1 class="display-2 font-weight-bold mb-2">
              Anmelden
            </h1>
          </div>
        </template>

        <v-card-text class="text-center">
          <v-form
            ref="login"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="email"
              required
              :rules="emailRules"
              color="red"
              label="E-Mail..."
              prepend-icon="mdi-email"
              name="email"
              @keydown.enter="_login"
            />

            <v-text-field
              v-model="password"
              required
              :rules="passwordRules"
              :type="pwdshow ? 'text' : 'password'"
              class="mb-5"
              color="red"
              label="Passwort..."
              prepend-icon="mdi-lock"
              name="password"
              :append-icon="pwdshow ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="pwdshow = !pwdshow"
              @keydown.enter="_login"
            />

            <v-btn
              class="mb-5"
              color="red"
              tile
              @click="_login"
              width="100%"
            >
              <v-icon class="mr-4">
                fa-sign-in-alt
              </v-icon>
              Anmelden
            </v-btn>
            <v-btn
              class="mb-5"
              color="white"
              tile
              to="/register"
              width="100%"
            >
              <v-icon class="mr-4">
                far fa-user-plus
              </v-icon>
              Neu registrieren
            </v-btn>
            <v-btn
              class="mb-5"
              color="white"
              tile
              to="/reset"
              width="100%"
            >
              <v-icon class="mr-4">
                far fa-key
              </v-icon>
              Passwort vergessen
            </v-btn>
          </v-form>
        </v-card-text>
      </base-material-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { useGraphQL } from '@/plugins/graphql'

export default {
  name: 'PagesLogin',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  data: () => ({
    valid: true,
    email: '',
    password: '',
    emailRules: [
      v => !!v || 'E-Mail wird benötigt!'
    ],
    passwordRules: [
      v => !!v || 'Passwort wird benötigt!'
    ],
    pwdshow: false
  }),

  computed: {
    ...mapGetters(['profile', 'isLogin'])
  },

  watch: {
    isLogin () {
      if (this.isLogin) {
        this.cl()
      }
    }
  },

  created () {
    if (this.isLogin) {
      this.cl()
    }
  },

  methods: {
    async _login () {
      if (!this.$refs.login.validate()) {
        return
      }

      await this.login(this.email, this.password)

      if (!this.isLogin) {
        this.$store.commit('OPEN_SNACKBAR', 'Falsche Zugangsdaten!')
      }
    },
    cl () {
      let target = this.$route.query.origin
      if (!target) target = '/'
      this.$router.replace({ path: target })
    }
  }
}
</script>
