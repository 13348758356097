<template>
  <v-edit-dialog
    :return-value.sync="wert"
    large
    persistent
    :save-text="savebutton"
    cancel-text="Abbrechen"
    @open="open"
    @save="save"
    @close="close"
  >
    <div>{{ value | dateformat(dateformat) }}</div>
    <template #input>
      <date-selector
        v-model="wert"
        :label="label"
        :clearable="clearable"
      />
    </template>
  </v-edit-dialog>
</template>

<script>
export default {
  name: 'EditDialog',

  props: {
    value: {
      type: String,
      required: false,
      default: undefined
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    savebutton: {
      type: String,
      required: false,
      default: 'Übernehmen'
    },
    dateformat: {
      type: String,
      required: false,
      default: null
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    wert: null
  }),

  methods: {
    open () {
      this.wert = this.value
    },
    close () {
      this.wert = null
    },
    save () {
      this.$emit('input', this.wert)
      this.wert = null
    }
  }
}
</script>

<style scoped>

</style>
