<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card
      v-if="!code"
      flat
    >
      <h1>Code fehlt. Bitte den Link aus der E-Mail nutzen!</h1>
    </v-card>
    <v-card
      v-else-if="!confirmed"
      flat
    >
      <h1>Bestätigung der Registrierung</h1>
      <p>Bitte geben Sie zur Bestätigung nochmals Ihre E-Mail-Adresse ein.</p>
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="email"
            label="E-Mail-Adresse"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="code"
            label="Bestätigungscode"
            readonly
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-btn
            block
            @click="confirm"
          >
            Bestätigen
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-else
      flat
    >
      <h1>Vielen Dank, Sie können sich nun anmelden.</h1>
    </v-card>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'PagesConfirm',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    code: {
      type: String,
      required: true
    }
  },

  data: () => ({
    email: null,
    confirmed: false
  }),

  methods: {
    confirm () {
      const err = []
      const EMAIL_ADDRESS_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

      if (!this.email) err.push('E-Mail-Adresse fehlt!')
      if (this.email && !EMAIL_ADDRESS_REGEX.test(this.email)) err.push('E-Mail-Adresse ungültig!')

      if (err.length !== 0) {
        this.$store.commit('OPEN_SNACKBAR', `<ul><li>${err.join('</li><li>')}</li></ul>`)
        return
      }

      this.$apollo.mutate({
        mutation: gql`mutation($email: String!, $confirmCode: String!) {
          PersonConfirmMail(email: $email, confirmCode: $confirmCode) { _id }
        }`,
        variables: {
          email: this.email,
          confirmCode: this.code
        }
      }).then(() => {
        this.confirmed = true
      }).catch(e => {
        this.$store.commit('OPEN_SNACKBAR', e)
      })
    }
  }
}
</script>
