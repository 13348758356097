var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isAdmin(_vm.id))?_c('e403'):_c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('h2',[_vm._v("Administratoren "+_vm._s(_vm.Organizer.name))]),_c('v-data-table',{attrs:{"items":_vm.Organizer.admins,"headers":[
      { text: 'Nachname', value: 'familyName' },
      { text: 'Vorname', value: 'givenName' },
      { text: 'zum Organisator zurückstufen', value: 'makeOrga' },
      { text: 'löschen', value: 'delete' }
    ],"items-per-page":-1,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.makeOrga",fn:function(ref){
    var item = ref.item;
return [(item._id !== _vm.$store.state.profile._id)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.makeOrga(item._id)}}},[_c('v-icon',[_vm._v("far fa-angle-down")])],1):_vm._e()]}},{key:"item.delete",fn:function(ref){
    var item = ref.item;
return [(item._id !== _vm.$store.state.profile._id)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.del(item._id)}}},[_c('v-icon',[_vm._v("far fa-trash-alt")])],1):_vm._e()]}}])}),_c('h2',[_vm._v("Organisatoren "+_vm._s(_vm.Organizer.name))]),_c('v-data-table',{attrs:{"items":_vm.Organizer.organizers,"headers":[
      { text: 'Nachname', value: 'familyName' },
      { text: 'Vorname', value: 'givenName' },
      { text: 'zum Admin hochstufen', value: 'makeAdmin' },
      { text: 'löschen', value: 'delete' }
    ],"items-per-page":-1,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.makeAdmin",fn:function(ref){
    var item = ref.item;
return [(item._id !== _vm.$store.state.profile._id)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.makeAdmin(item._id)}}},[_c('v-icon',[_vm._v("far fa-angle-up")])],1):_vm._e()]}},{key:"item.delete",fn:function(ref){
    var item = ref.item;
return [(item._id !== _vm.$store.state.profile._id)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.del(item._id)}}},[_c('v-icon',[_vm._v("far fa-trash-alt")])],1):_vm._e()]}}])}),_c('h2',[_vm._v("Ausstehende Registrierungen "+_vm._s(_vm.Organizer.name))]),_c('v-data-table',{attrs:{"items":_vm.Organizer.pending,"headers":[
      { text: 'Nachname', value: 'familyName' },
      { text: 'Vorname', value: 'givenName' },
      { text: 'als Organisator hinzufügen', value: 'makeOrga' },
      { text: 'als Admin hinzufügen', value: 'makeAdmin' },
      { text: 'löschen', value: 'delete' }
    ],"items-per-page":-1,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.makeOrga",fn:function(ref){
    var item = ref.item;
return [(item._id !== _vm.$store.state.profile._id)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.makeOrga(item._id)}}},[_c('v-icon',[_vm._v("far fa-angle-up")])],1):_vm._e()]}},{key:"item.makeAdmin",fn:function(ref){
    var item = ref.item;
return [(item._id !== _vm.$store.state.profile._id)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.makeAdmin(item._id)}}},[_c('v-icon',[_vm._v("far fa-angle-double-up")])],1):_vm._e()]}},{key:"item.delete",fn:function(ref){
    var item = ref.item;
return [(item._id !== _vm.$store.state.profile._id)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.del(item._id)}}},[_c('v-icon',[_vm._v("far fa-trash-alt")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }