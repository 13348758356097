<template>
  <e403 v-if="!isOrga(id)" />
  <v-container
    v-else
    fluid
    tag="section"
  >
    <v-card
      flat
    >
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="open(null)"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>

      <v-col>
        <v-text-field
          v-model="filter"
          label="Filter"
        />
      </v-col>

      <v-data-table
        :headers="headers"
        :items="EventFind"
        sort-by="date"
        sort-desc
        :items-per-page="15"
        mobile-breakpoint="0"
        :search="filter"
        @click:row="open"
      >
        <template #item.date="{item}">
          {{ item.date | date }}
        </template>
        <template #item.open="{item}">
          <v-btn
            fab
            small
            @click.stop="start(item)"
          >
            <v-icon>far fa-play</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <edit-event
      :id="dialog.id"
      v-model="dialog.open"
      :organizer="id"
    />
  </v-container>
</template>

<script>
import { useAuth } from '@/plugins/auth'
import gql from 'graphql-tag'
import { deleteQuery, updateQuery } from '@/plugins/graphql'

export default {
  name: 'event',

  components: {
    EditEvent: () => import('./dialogs/EditEvent')
  },

  setup (props, context) {
    return {
      ...useAuth(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    headers: [
      {
        text: 'Datum',
        value: 'date',
        sortable: false
      },
      {
        text: 'Name',
        value: 'name',
        sortable: false
      },
      {
        text: 'Öffnen',
        value: 'open',
        sortable: false,
        width: 100,
        align: 'center'
      }
    ],
    dialog: {
      open: false,
      id: null
    },
    filter: ''
  }),

  apollo: {
    EventFind: {
      query: gql`query($organizer: UUID!) { EventFind(organizer: $organizer) { _id name date _organizer } }`,
      variables () {
        return {
          organizer: this.id
        }
      },
      subscribeToMore: {
        document: gql`subscription($organizer: UUID!) { EventUpdated(organizer: $organizer) { _id name date _organizer } }`,
        variables () {
          return {
            organizer: this.id
          }
        },
        updateQuery: updateQuery('EventFind', 'EventUpdated')
      }
    },
    $subscribe: {
      EventDeleted: {
        query: gql`subscription($organizer: UUID!) { EventDeleted(organizer: $organizer) }`,
        variables () {
          return {
            organizer: this.id
          }
        },
        result (id) {
          deleteQuery('EventFind', 'EventDeleted', this.EventFind, id)
        }
      }
    }
  },

  methods: {
    open (item) {
      this.dialog.open = true
      this.dialog.id = item?._id
    },
    start (item) {
      if (!item?._id) return
      this.$router.push(`/event/${item._id}`)
    }
  }
}
</script>

<style scoped>

</style>
