<template>
  <div>
    <router-view />
    <div
      v-if="!connected"
      id="loader"
    >
      <div>
        <v-progress-circular
          indeterminate
          :size="64"
          :width="8"
          color="rgb(255, 4, 29)"
        />
        <p>Verbinde zu Server...</p>
      </div>
    </div>
    <confirm ref="confirm" />
    <v-snackbar
      v-model="error_visible"
      class="error_snack"
      :timeout="5000"
      top
      multi-line
    >
      <template
        #default
      >
        <span v-html="error_text" />
      </template>

      <template #action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="error_visible = false"
        >
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Vue from 'vue'
import { useGraphQL } from '@/plugins/graphql'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'App',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  computed: {
    ...mapState(['profile']),
    error_text () {
      return this.$store.state.snackbar.text
    },
    error_visible: {
      get () {
        return this.$store.state.snackbar.visible
      },
      set (v) {
        if (!v) this.$store.commit('CLOSE_SNACKBAR')
      }
    }
  },

  mounted () {
    this.login()
  }
})
</script>

<style scoped>
#loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
}

#loader > div {
  height: 100vh;
  width: 100vw;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

#loader > div > p {
  font-family: "RobotoCondensed", sans-serif;
  margin-top: 16px;
}
</style>

<style>
@font-face {
  font-family: "Arimo";
  src: local("Arimo"), url("./assets/fonts/Arimo/Arimo-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Arimo";
  font-style: italic;
  src: local("Arimo"), url("./assets/fonts/Arimo/Arimo-Italic-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoCondensed";
  src: local("RobotoCondensed"), url("./assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoCondensed";
  font-weight: bold;
  src: local("RobotoCondensed"), url("./assets/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoCondensed";
  font-style: italic;
  src: local("RobotoCondensed"), url("./assets/fonts/Roboto_Condensed/RobotoCondensed-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoCondensed";
  font-weight: bold;
  font-style: italic;
  src: local("RobotoCondensed"), url("./assets/fonts/Roboto_Condensed/RobotoCondensed-BoldItalic.ttf") format("truetype");
}

.changed {
  color: rgb(255, 4, 29);
}

.changefade {
  color: inherit;
  transition: color 0.8s;
}

.trhover:hover {
  cursor: pointer;
}

.center_switch > div > div > div {
  margin: auto !important;
}

.error_snack .v-snack__wrapper {
  font-family: "RobotoCondensed", sans-serif;
  background-color: red !important;
}

.row-clickable tbody tr:hover  {
  cursor: pointer;
}

h1 {
  font-family: "RobotoCondensed", sans-serif;
}
</style>
