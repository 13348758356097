<template>
  <v-container
    fluid
    tag="section"
  >
    <v-tabs
      v-model="tab"
      background-color="primary lighten-1"
      fixed-tabs
    >
      <v-tab
        href="#apparatus"
      >
        Geräte
      </v-tab>
      <v-tab
        href="#timeslot"
      >
        Zeitslots
      </v-tab>
      <v-tab
        href="#ranking"
      >
        Rangliste
      </v-tab>
      <v-tab
        href="#urkunden"
      >
        Urkunden
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item
        value="apparatus"
      >
        <v-card
          flat
          class="ma-0"
        >
          <v-card-title class="text-h3">
            Geräte
            <v-spacer />
            <v-menu
              v-if="!hasresults"
            >
              <template #activator="{on, attrs}">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    fa-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="a in geraete"
                  :key="a._id"
                  @click="addApparatus(a._id)"
                >
                  <v-list-item-title>{{ a.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-text>
            <draggable
              :list="[]"
              handle=".handle"
              tag="div"
              class="row row-dense"
              v-bind="dragOptions"
              @end="orderApparatus"
              :disabled="hasresults"
            >
              <v-col
                v-for="(a) in (Event || {}).apparatus"
                :key="a.name"
                cols="6"
                lg="3"
                class="item"
              >
                <v-card>
                  <v-card-title class="text-h4">
                    <v-icon
                      v-if="!hasresults"
                      class="handle mr-4"
                    >far fa-bars</v-icon>
                    {{ a.apparatus.name }}
                  </v-card-title>
                  <v-card-text>
                    <p><b>Elemente: </b> {{ a.elements }}</p>
                    <p><b>Bonus: </b> {{ a.bonus | float2_0 }}</p>
                    <p><b>Malus: </b> {{ a.malus | float2_0 }}</p>
                  </v-card-text>
                  <v-card-actions
                    v-if="!hasresults"
                  >
                    <v-spacer />
                    <v-btn
                      icon
                      @click="openapparatusedit(a)"
                    >
                      <v-icon>far fa-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      @click="deleteApparatus(a._apparatus)"
                    >
                      <v-icon>far fa-trash</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </draggable>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item
        value="timeslot"
      >
        <v-card
          flat
        >
          <v-btn
            v-if="ts.selected.length > 0"
            absolute
            top
            right
            fab
            small
            style="margin-right:60px;"
            @click="deleteTimeslots"
          >
            <v-icon>
              fa-minus
            </v-icon>
          </v-btn>
          <v-btn
            absolute
            top
            right
            fab
            small
            @click="ts.dialog.open = true"
          >
            <v-icon>
              fa-plus
            </v-icon>
          </v-btn>
          <v-data-table
            :headers="ts.headers"
            :items="timeslots"
            sort-by="time"
            :items-per-page="-1"
            mobile-breakpoint="0"
          >
            <template #header.select>
              <v-checkbox
               @change="(v) => !v ? ts.selected = [] : ts.selected = timeslots.filter(t => !t.team).map(t => t._id)"
              />
            </template>
            <template #item.select="{item}">
              <v-checkbox
                v-if="!item.team"
                v-model="ts.selected"
                :value="item._id"
                multiple
              />
            </template>
            <template #item.time="{item}">
              {{ `1900-01-01 ${item.time}` | dateformat('HH:mm') }}
            </template>
            <template #item.end="{item}">
              {{ `1900-01-01 ${addMinutes(item.time, item.duration)}` | dateformat('HH:mm') }}
            </template>
            <template #item.team="{item}">
              <v-btn
                v-if="item.team"
                text
                x-small
                style="float: left;"
                @click="deleteTeam(item._id)"
              >
                <v-icon small>
                  far fa-trash-alt
                </v-icon>
              </v-btn>
              <base-edit-dialog
                :value="item.team ? item.team.name : ''"
                label="Team hinzufügen"
                @input="(v) => addTeam(item._id, v)"
              />
            </template>
            <template #item.result="{item}">
              <v-btn
                v-if="item.team"
                text
                x-small
                style="float: left;"
                @click="resultopen(item._id)"
              >
                <v-icon small>
                  far fa-play
                </v-icon>
              </v-btn>
              {{ item.result ? item.result.calctime + 's' : '' }}
            </template>
          </v-data-table>
        </v-card>
        <base-material-dialog
          v-model="ts.dialog.open"
          title="Zeitslots hinzufügen"
          color="rgb(255, 4, 29)"
          :actions="['save','cancel']"
          @save="addTimeslots"
          @close="ts.dialog.open = false"
          @esc="ts.dialog.open = false"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-menu
                ref="beginn"
                v-model="ts.dialog.beginnmenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="ts.dialog.beginn"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="ts.dialog.beginn"
                    label="Erster Zeitslot"
                    prepend-icon="far fa-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="ts.dialog.beginnmenu"
                  v-model="ts.dialog.beginn"
                  full-width
                  format="24hr"
                  @click:minute="$refs.beginn.save(`${ts.dialog.beginn}:00`)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-menu
                ref="ende"
                v-model="ts.dialog.endemenue"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="ts.dialog.ende"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="ts.dialog.ende"
                    label="Bis"
                    prepend-icon="far fa-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="ts.dialog.endemenue"
                  v-model="ts.dialog.ende"
                  full-width
                  format="24hr"
                  @click:minute="$refs.ende.save(`${ts.dialog.ende}:00`)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="ts.dialog.duration"
                label="Dauer eines Zeitslots (in min)"
                type="number"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="ts.dialog.pause"
                label="Pause nach einem Zeitslot (in min)"
                type="number"
              />
            </v-col>
          </v-row>

          <h3>Es werden folgende {{ newtimeslots.length }} Zeitslots angelegt:</h3>
          <v-data-table
            :headers="ts.dialog.headers"
            :items="newtimeslots"
            :items-per-page="-1"
          >
            <template #item.time="{item}">
              {{ `1900-01-01 ${item.time}` | dateformat('HH:mm') }}
            </template>
            <template #item.end="{item}">
              {{ `1900-01-01 ${addMinutes(item.time, item.duration)}` | dateformat('HH:mm') }}
            </template>
          </v-data-table>
        </base-material-dialog>
        <base-material-dialog
          v-model="result.open"
          title="Ergebnis eingeben"
          color="rgb(255, 4, 29)"
          :actions="['save','cancel']"
          @save="saveresult"
          @close="resultclose"
          @esc="resultclose"
        >
          <v-row
            class="mt-4"
          >
            <v-col
              cols="12"
              class="mb-6"
            >
              <base-material-card
                title="Zeitmessung"
                color="rgb(150,150,150)"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      :value="result.time | float2_0"
                      @change="(v) => result.time = !isNaN(parseFloat(v)) ? parseFloat(v) : 0"
                      label="gemessene Zeit"
                      class="zeitmessung"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      readonly
                      :value="`${bonusmalus}s`"
                      :label="bonusmalus <= 0 ? 'Bonus' : 'Malus'"
                      class="zeitmessung"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      readonly
                      :value="result.time + bonusmalus | float2_0"
                      label="berechnete Zeit"
                      class="zeitmessung"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="3"
                    order-md="0"
                  >
                    <v-btn
                      block
                      @click="start(5)"
                    >
                      5s Countdown
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    md="3"
                    order-md="4"
                  >
                    <v-btn
                      block
                      @click="reset()"
                    >
                      Reset
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    md="3"
                    order-md="1"
                  >
                    <v-btn
                      block
                      color="green"
                      @click="start(0)"
                    >
                      Start
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    md="3"
                    order-md="2"
                  >
                    <v-btn
                      block
                      color="primary"
                      @click="stop()"
                    >
                      Stop
                    </v-btn>
                  </v-col>
                </v-row>
              </base-material-card>
            </v-col>
            <v-col
              v-for="(g, i) in apparatus"
              :key="g._apparatus"
              cols="12"
              sm="6"
              md="3"
            >
              <base-material-card
                :title="g.apparatus.name"
                color="rgb(150,150,150)"
              >
                <!--v-checkbox
                  v-model="results(g._apparatus).teile"
                  :label="`Teile OK? (max ${g.elements}, Malus +${g.malus}s)`"
                /-->
                <v-text-field
                  type="number"
                  min="0"
                  max="3"
                  v-model="results(g._apparatus).fehler"
                  :label="`fehlende oder fehlerhafte Teile (Malus jeweils +${g.malus}s)`"
                />
                <v-checkbox
                  v-model="results(g._apparatus).bonusteil"
                  :label="`Bonusteil? (Bonus -${g.bonus}s)`"
                />
                <v-checkbox
                  v-model="results(g._apparatus).bonus"
                  :label="`Bonus? (Bonus -${g.bonus}s)`"
                />
                <v-checkbox
                  v-if="i !== apparatus.length -1"
                  v-model="results(g._apparatus).wechsel"
                  :label="`Wechsel OK? (Malus +${g.malus}s)`"
                />
            </base-material-card>
            </v-col>
          </v-row>
        </base-material-dialog>
      </v-tab-item>
      <v-tab-item
        value="ranking"
      >
        <v-data-table
          :headers="ergebnis.headers"
          :items="ergebnisse"
          sort-by="time"
          :items-per-page="-1"
        >
          <template #item.time="{item}">
            {{ item.time | float2_0 }}s
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item
        value="urkunden"
      >
        <v-card
          flat
        >
          <v-row>
            <v-col
              cols="12"
              xl="6"
              order-xl="2"
            >
              <v-row>
                <v-col cols="12">
                  <v-btn
                    block
                    @click="generatePDF(true)"
                  >
                    Urkunden inklusive Hintergrund
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    block
                    @click="generatePDF(false)"
                  >
                    Urkunden für Vordruck
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    block
                    @click="generatePDF(true, 'lktf23')"
                  >
                    LKTF 2023: Urkunden inklusive Hintergrund
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    block
                    @click="generatePDF(false, 'lktf23')"
                  >
                    LKTF 2023: Urkunden für Vordruck
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              order-xl="1"
            >
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="urkunde.name"
                    label="Name auf Urkunde"
                  />
                </v-col>
                <v-col cols="6">
                  <v-btn
                    fab
                    small
                    @click="urkunde.name = person"
                    class="mr-2"
                  >
                    <v-icon>far fa-long-arrow-alt-left</v-icon>
                  </v-btn>
                  übernehme &quot;{{ person }}&quot;
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="urkunde.schule"
                    label="Organisator auf Urkunde"
                  />
                </v-col>
                <v-col cols="6">
                  <v-btn
                   fab
                   small
                   @click="urkunde.schule = organizer"
                   class="mr-2"
                  >
                    <v-icon>far fa-long-arrow-alt-left</v-icon>
                  </v-btn>
                  übernehme &quot;{{ organizer }}&quot;
                </v-col>
                <v-col cols="12">
                  Unterschrift auf Urkunde:<br>
                  <v-image-input
                    v-model="urkunde.unterschrift"
                    clearable
                    :image-height="256"
                    :image-width="512"
                    image-min-scaling="contain"
                    hide-actions
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <edit-eventapparatus
      v-model="apparatusdialog.open"
      :id="apparatusdialog.id"
      :elements="apparatusdialog.elements"
      :bonus="apparatusdialog.bonus"
      :malus="apparatusdialog.malus"
      @save="({id, elements, bonus, malus}) => saveApparatus(id, elements, bonus, malus)"
    />
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import moment from 'moment'
import { generateUrkunde } from '@/urkunde'

const timeslotquery = '_id time duration team { _id name } result { bonus runtime calctime results { _id teile bonus wechsel bonusteil fehler } }'
const query = `_id name date _organizer organizer { name } apparatus { _apparatus apparatus { name logo } elements bonus malus } timeslots { ${timeslotquery} }`

export default {
  name: 'Event',

  components: {
    EditEventapparatus: () => import('./dialogs/EditEventapparatus')
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    Event: null,
    ApparatusFind: [],
    tab: 0,
    apparatusdialog: {
      open: false,
      id: null,
      elements: null,
      bonus: null,
      malus: null
    },
    ts: {
      headers: [
        {
          text: '',
          value: 'select',
          sortable: false,
          width: 75
        },
        {
          text: 'Beginn',
          value: 'time',
          sortable: false
        },
        {
          text: 'Ende',
          value: 'end',
          sortable: false
        },
        {
          text: 'Team',
          value: 'team',
          sortable: false
        },
        {
          text: 'Ergebnis',
          value: 'result',
          sortable: false
        }
      ],
      selected: [],
      dialog: {
        open: false,
        beginn: '09:00:00',
        ende: '12:00:00',
        duration: 4,
        pause: 1,
        beginnmenue: false,
        endemenue: false,
        headers: [
          {
            text: 'Beginn',
            value: 'time',
            sortable: false
          },
          {
            text: 'Ende',
            value: 'end',
            sortable: false
          }
        ]
      }
    },
    result: {
      open: false,
      id: null,
      results: [],
      start: null,
      stop: null,
      time: null,
      running: false,
      interval: null
    },
    ergebnis: {
      headers: [
        {
          text: 'Platz',
          value: 'platz',
          sortable: false
        },
        {
          text: 'Mannschaft',
          value: 'team',
          sortable: false
        },
        {
          text: 'Zeit',
          value: 'time',
          sortable: false
        }
      ]
    },
    urkunde: {
      name: '',
      schule: '',
      unterschrift: null
    }
  }),

  computed: {
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    geraete () {
      return this.ApparatusFind?.slice()?.sort((a, b) => a.name < b.name ? -1 : 1)?.filter(g => !this.Event?.apparatus.find(a => a._apparatus === g._id)) || []
    },
    apparatus () {
      return this.Event?.apparatus || []
    },
    timeslots () {
      return this.Event?.timeslots || []
    },
    newtimeslots () {
      const neu = []

      if (this.ts.dialog.duration <= 0) return neu

      for (let t = this.ts.dialog.beginn; t < this.ts.dialog.ende; t = moment(`1900-01-01 ${t}`).add(parseInt(this.ts.dialog.duration) + parseInt(this.ts.dialog.pause), 'minutes').format('HH:mm:ss')) {
        neu.push(t)
      }

      return neu.filter(n => !this.timeslots.find(o => o.time === n && o.duration === parseInt(this.ts.dialog.duration))).map(t => ({ time: t, duration: parseInt(this.ts.dialog.duration) }))
    },
    bonus () {
      return this.apparatus.reduce((acc, curr) => {
        if (this.results(curr._apparatus).bonus) acc += parseInt(curr.bonus)
        if (this.results(curr._apparatus).bonusteil) acc += parseInt(curr.bonus)
        return acc
      }, 0)
    },
    malus () {
      return this.apparatus.reduce((acc, curr, i, arr) => {
        if (!this.results(curr._apparatus).teile) acc += parseInt(curr.malus)

        const f = parseInt(this.results(curr._apparatus).fehler)
        if (!!f && !isNaN(f)) {
          acc += f * parseInt(curr.malus)
        }
        if (i !== arr.length - 1 && !this.results(curr._apparatus).wechsel) acc += parseInt(curr.malus)
        return acc
      }, 0)
    },
    bonusmalus () {
      return this.malus - this.bonus
    },
    hasresults () {
      return !!this.Event?.timeslots?.find(t => !!t.result)
    },
    ergebnisse () {
      return this.Event?.timeslots?.reduce((acc, curr) => {
        if (curr.team && curr.result) {
          acc.push({ team: curr.team.name, time: curr.result.calctime })
        }
        return acc
      }, []).sort((a, b) => a.time < b.time ? -1 : 1).map((m, i) => ({ ...m, platz: i + 1 }))
    },
    organizer () {
      return this.Event?.organizer?.name
    },
    person () {
      return `${this.$store.getters.profile?.givenName} ${this.$store.getters.profile?.familyName}`
    }
  },

  methods: {
    addApparatus (id) {
      this.$apollo.mutate({
        mutation: gql`mutation($id: UUID!, $apparatus: UUID!) {
          EventAddApparatus(id: $id, apparatus: $apparatus) { ${query} }
        }`,
        variables: {
          id: this.id,
          apparatus: id
        }
      })
    },
    deleteApparatus (id) {
      this.$apollo.mutate({
        mutation: gql`mutation($id: UUID!, $apparatus: UUID!) {
          EventDeleteApparatus(id: $id, apparatus: $apparatus) { ${query} }
        }`,
        variables: {
          id: this.id,
          apparatus: id
        }
      })
    },
    orderApparatus (a) {
      const src = a.oldIndex
      const target = a.newIndex

      if (src === target) return

      this.$apollo.mutate({
        mutation: gql`mutation($id: UUID!, $src: Int!, $target: Int!) {
          EventOrderApparatus(id: $id, src: $src, target: $target) { ${query} }
        }`,
        variables: {
          id: this.id,
          src,
          target
        }
      }).then((r) => {
        this.Event.apparatus = r.data.EventOrderApparatus.apparatus
      })
    },
    openapparatusedit (a) {
      this.apparatusdialog.elements = a.elements
      this.apparatusdialog.bonus = a.bonus
      this.apparatusdialog.malus = a.malus
      this.apparatusdialog.id = a._apparatus
      this.apparatusdialog.open = true
    },
    saveApparatus (id, e, b, m) {
      this.$apollo.mutate({
        mutation: gql`mutation($id: UUID!, $apparatus: UUID!, $elements: Int!, $bonus: Float!, $malus: Float!) {
          EventUpdateApparatus(id: $id, apparatus: $apparatus, elements: $elements, bonus: $bonus, malus: $malus) { ${query} }
        }`,
        variables: {
          id: this.id,
          apparatus: id,
          elements: e,
          bonus: b,
          malus: m
        }
      })
    },
    addTimeslots () {
      if (this.newtimeslots.length > 0) {
        this.$apollo.mutate({
          mutation: gql`mutation($id: UUID!, $timeslots: [ITimeslot!]!) {
            EventAddTimeslots(id: $id, timeslots: $timeslots) { ${query} }
          }`,
          variables: {
            id: this.id,
            timeslots: this.newtimeslots
          }
        }).then((r) => {
          this.Event.timeslots = r.data.EventAddTimeslots.timeslots
        })
      }

      this.ts.dialog.open = false
    },
    deleteTimeslots () {
      this.$apollo.mutate({
        mutation: gql`mutation($id: UUID!, $timeslots: [UUID!]!) {
          EventDeleteTimeslots(id: $id, timeslots: $timeslots) { ${query} }
        }`,
        variables: {
          id: this.id,
          timeslots: this.ts.selected
        }
      }).then((r) => {
        this.Event.timeslots = r.data.EventDeleteTimeslots.timeslots
        this.ts.selected = []
      })
    },
    addMinutes (time, min) {
      return moment(`1900-01-01 ${time}`).add(min, 'minutes').format('HH:mm:ss')
    },
    addTeam (timeslot, name) {
      this.$apollo.mutate({
        mutation: gql`mutation($id: UUID!, $timeslot: UUID!, $name: String!) {
          EventAddTeam(id: $id, timeslot: $timeslot, name: $name) { ${query} }
        }`,
        variables: {
          id: this.id,
          timeslot,
          name
        }
      }).then((r) => {
        this.Event.timeslots = r.data.EventAddTeam.timeslots
        this.ts.selected = []
      })
    },
    async deleteTeam (timeslot) {
      if (!this.timeslots.find(t => t._id === timeslot)?.result || await this.$root.$children[0].$refs.confirm.open('Löschen', 'Bei diesem Team sind bereits Ergebnisse hinterlegt, wirklich löschen?')) {
        this.$apollo.mutate({
          mutation: gql`mutation($id: UUID!, $timeslot: UUID!) {
            EventDeleteTeam(id: $id, timeslot: $timeslot) { ${query} }
          }`,
          variables: {
            id: this.id,
            timeslot
          }
        }).then((r) => {
          this.Event.timeslots = r.data.EventDeleteTeam.timeslots
          this.ts.selected = []
        })
      }
    },
    resultopen (timeslot) {
      const result = this.Event?.timeslots?.find(t => t._id === timeslot)?.result
      const results = result?.results

      this.result.time = result?.runtime

      this.result.open = true
      this.result.id = timeslot
      this.result.results = this.apparatus.map((a, i, arr) => {
        const data = results?.find(r => r._id === a._apparatus)

        const ret = {
          _id: a._apparatus,
          teile: data?.teile !== undefined ? data.teile : true,
          bonus: data?.bonus !== undefined ? data.bonus : false,
          bonusteil: data?.bonusteil !== undefined ? data.bonusteil : false,
          fehler: data?.fehler || 0
        }
        if (i !== arr.length - 1) {
          ret.wechsel = data?.wechsel !== undefined ? data.wechsel : true
        }
        return ret
      })
    },
    resultclose () {
      this.result.open = false
      this.result.id = null
      this.result.results = []
      this.reset()
    },
    results (apparatus) {
      return this.result?.results?.find(a => a._id === apparatus) || {}
    },
    start (countdown) {
      if (this.result.running) return

      this.result.start = Date.now() + (countdown || 0) * 1000
      this.result.running = true
      this.result.interval = setInterval(() => {
        this.result.time = (Date.now() - this.result.start) / 1000
      }, 10)
    },
    stop () {
      this.result.stop = Date.now()
      this.result.running = false
      if (this.result.interval !== null) {
        clearInterval(this.result.interval)
        this.result.interval = null
      }
    },
    reset () {
      this.result.start = null
      this.result.stop = null
      this.result.time = null
      this.result.running = false
      if (this.result.interval !== null) {
        clearInterval(this.result.interval)
        this.result.interval = null
      }
    },
    saveresult () {
      const variables = {
        id: this.id,
        timeslot: this.result.id,
        result: {
          runtime: Math.round((this.result.time) * 100) / 100,
          bonus: this.bonusmalus,
          calctime: Math.round((this.result.time + this.bonusmalus) * 100) / 100,
          results: this.result.results?.map(r => ({
            ...r,
            fehler: parseInt(r.fehler)
          })) || []
        }
      }

      this.$apollo.mutate({
        mutation: gql`mutation($id: UUID!, $timeslot: UUID!, $result: IResult!) {
            EventSetResult(id: $id, timeslot: $timeslot, result: $result) { ${timeslotquery} }
          }`,
        variables
      }).then(() => {
        this.ts.selected = []
      })

      this.resultclose()
    },
    async generatePDF (vorlage = false, typ = 'sim') {
      generateUrkunde(this.ergebnisse.map(e => ({ platz: `${e.platz}. PLATZ`, zeit: `${Math.floor(e.time / 60)} MIN. ${Math.floor(e.time % 60)} SEK.`, team: e.team })), this.urkunde.schule, this.urkunde.name, this.urkunde.unterschrift, vorlage, typ)
    }
  },

  apollo: {
    Event: {
      query: gql`query($event: UUID!) { Event(id: $event) { ${query} } }`,
      variables () {
        return {
          event: this.id
        }
      },
      subscribeToMore: {
        document: gql`subscription($id: UUID!) { EventUpdated(id: $id) { ${query} } }`,
        variables () {
          return {
            id: this.id
          }
        }
      }
    },
    ApparatusFind: {
      query: gql`query { ApparatusFind { _id name }}`
    }
  }
}
</script>

<style scoped>
.apparatus {
  width: calc(25% - 20px);
  margin: 10px;
  float: left;
}

.draggable:after {
  display: block;
  content: '';
  clear: both;
}

.v-text-field.zeitmessung >>> input {
  font-size: 3em !important;
  font-weight: bold !important;
  max-height: none;
}

</style>
