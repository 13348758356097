import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Index'),
    children: [
      {
        path: '',
        name: 'Startseite',
        component: () => import('../views/components/start.vue')
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/Login')
      },
      {
        path: 'reset/:code?',
        name: 'Passwort zurücksetzen',
        component: () => import('../views/Reset'),
        props: true
      },
      {
        path: 'register',
        name: 'Registrierung',
        component: () => import('../views/Register')
      },
      {
        path: 'confirm/:code?',
        name: 'Bestätigung',
        component: () => import('../views/Confirm'),
        props: true
      },
      {
        path: 'management/person',
        name: 'Personen bearbeiten',
        component: () => import('../views/components/management/person')
      },
      {
        path: 'management/apparatus',
        name: 'Geräte bearbeiten',
        component: () => import('../views/components/management/apparatus')
      },
      {
        path: 'management/organizer',
        name: 'Veranstalter bearbeiten',
        component: () => import('../views/components/management/organizer')
      },
      {
        path: 'orga/:id',
        name: 'Übersicht Events',
        component: () => import('../views/components/orga/event'),
        props: true
      },
      {
        path: 'event/:id',
        name: 'Event',
        component: () => import('../views/components/event/event'),
        props: true
      },
      {
        path: 'e/:id',
        name: 'Event',
        component: () => import('../views/components/event/public'),
        props: true
      },
      {
        path: 'admin/:id',
        name: 'Schulverwaltung',
        component: () => import('../views/components/admin/school'),
        props: true
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
