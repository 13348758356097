<template>
  <base-material-dialog
    :value="value"
    icon="mdi-home"
    title="Event bearbeiten"
    :sub-title="id ? id : 'NEU'"
    color="rgb(255, 4, 29)"
    :actions="[doc && orga ? 'del' : '', !id || orga ? 'save' : '', 'cancel']"
    @del="del"
    @save="save"
    @close="close"
    @esc="close"
  >
    <v-row v-if="id && (!data || !data._organizer)">
      Warte auf Daten...
    </v-row>
    <v-row v-else-if="id && !isOrga(data._organizer)">
      Kein Zugriff!
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
      >
        <date-selector
          v-model="data.date"
          label="Datum"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-text-field
          v-model="data.name"
          label="Name"
        />
      </v-col>
    </v-row>
  </base-material-dialog>
</template>

<script>
import { useAuth } from '@/plugins/auth'
import { useEditDialog } from '@/plugins/editdialog'

export default {
  name: 'EditEvent',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      default: null
    },
    organizer: {
      type: String,
      required: true
    }
  },

  setup (props, context) {
    return {
      ...useAuth(context),
      ...useEditDialog(props, context, 'Event(id: $id) { _id name date _organizer }', ['name', 'date'])
    }
  },

  computed: {
    orga () {
      return this.isOrga(this.data?._organizer)
    }
  },

  methods: {
    update () {
      return {
        mutation: `mutation($id: UUID!, $name: String, $date: Date) {
          EventUpdate(id: $id, name: $name, date: $date) {
            _id name date
          }
        }`
      }
    },
    create () {
      return {
        mutation: `mutation($name: String!, $date: Date!, $organizer: UUID!) {
          EventCreate(name: $name, date: $date, organizer: $organizer) {
            _id name date _organizer
          }
        }`,
        variables: {
          organizer: this.organizer
        }
      }
    }
  }
}
</script>

<style lang="sass">
  $dialog-elevation: 0
</style>
