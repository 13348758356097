<template>
  <v-container
    fluid
    tag="section"
  >
    <h1>schoolINmotion</h1>
    <img src="../../assets/logo_sim.jpg" />
    <h2><b>schoolINmotion</b> ist ein Mannschaftswettbewerb, bei dem eine Anzahl von zwei bis vier Schülerinnen und Schülern in einem Team antreten. Es ist dabei unerheblich, ob das Team aus nur Jungen, nur Mädchen oder als gemischtes Team zusammengestellt wird.</h2>
    <h3>Ziel des Wettbewerbs ist es, eine möglichst schnelle Zeit in einem Geräte-Parcours zu erzielen. Zusätzlich sind an den einzelnen Geräten turnerische Elemente zu erfüllen.</h3>
    <h3>Der zu absolvierende Parcours besteht aus 4 Geräten, an welchen jeweils drei Elemente gezeigt werden müssen. Jedes Teammitglied kann eine oder zwei Geräte turnen, allerdings nicht direkt hintereinander. Es zählt die Team-Zeit, nicht die beste Ausführung! Auf turnerische Aspekte, wie die korrekte Ausführung von Pflichtübungen wird bewusst weniger Wert gelegt. So garantiert das gemeinsame Turnen Spaß und Action.</h3>
  </v-container>
</template>

<script>
export default {
  name: 'start_sim'
}
</script>

<style scoped>
img {
  display: block;
  max-width: calc(100% - 48px);
  width: 400px;
  height: auto;
  /* float: right; */
  margin: 24px auto;
}

h1 {
  font-size: 350%;
  text-align: center;
}

h2 {
  line-height: 180%;
  margin-bottom: 16px;
  letter-spacing: 0.2px;
}

h3 {
  line-height: 180%;
  letter-spacing: 0.2px;
  margin-bottom: 12px;
}
</style>
