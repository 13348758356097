<template>
  <e403 v-if="!isMaster" />
  <v-container
    v-else
    fluid
    tag="section"
  >
    <v-card
      flat
    >
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="open(null)"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>

      <v-col>
        <v-text-field
          v-model="filter"
          label="Filter"
        />
      </v-col>

      <v-data-table
        :headers="headers"
        :items="OrganizerFind"
        sort-by="plz"
        :items-per-page="15"
        mobile-breakpoint="0"
        :search="filter"
        @click:row="open"
      />
    </v-card>
    <edit-organizer
      :id="dialog.id"
      v-model="dialog.open"
    />
  </v-container>
</template>

<script>
import { useAuth } from '@/plugins/auth'
import gql from 'graphql-tag'
import { updateQuery, deleteQuery } from '@/plugins/graphql'

export default {
  name: 'People',

  components: {
    EditOrganizer: () => import('./dialogs/EditOrganizer')
  },

  setup (props, context) {
    return {
      ...useAuth(context)
    }
  },

  data: () => ({
    OrganizerFind: [],
    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true
      },
      {
        text: 'PLZ',
        value: 'plz',
        sortable: true
      },
      {
        text: 'Ort',
        value: 'ort',
        sortable: true
      }
    ],
    dialog: {
      open: false,
      id: null
    },
    filter: ''
  }),

  apollo: {
    OrganizerFind: {
      query: gql`query { OrganizerFind { _id name plz ort admins { _id givenName familyName } organizers { _id givenName familyName } } }`,
      subscribeToMore: {
        document: gql`subscription { OrganizerUpdated { _id name plz ort admins { _id givenName familyName } organizers { _id givenName familyName } } }`,
        updateQuery: updateQuery('OrganizerFind', 'OrganizerUpdated')
      }
    },
    $subscribe: {
      PersonDeleted: {
        query: gql`subscription { OrganizerDeleted }`,
        result (id) {
          deleteQuery('OrganizerFind', 'OrganizerDeleted', this.OrganizerFind, id)
        }
      }
    }
  },

  methods: {
    open (item) {
      this.dialog.open = true
      this.dialog.id = item?._id
    }
  }
}
</script>

<style scoped>
</style>
