<template>
  <base-material-dialog
    :value="value"
    icon="mdi-home"
    title="Person bearbeiten"
    :sub-title="id ? id : 'NEU'"
    color="rgb(255, 4, 29)"
    :actions="[doc && isMaster ? 'del' : '', isMaster ? 'save' : '', 'cancel']"
    @del="del"
    @save="save"
    @close="close"
    @esc="close"
  >
    <v-row v-if="!isMaster">
      Kein Zugriff! {{ isMaster }}
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="data.name"
          label="Name"
        />
      </v-col>
      <v-col
        cols="4"
        sm="2"
      >
        <v-text-field
          v-model="data.plz"
          label="PLZ"
        />
      </v-col>
      <v-col
        cols="8"
        sm="4"
      >
        <v-text-field
          v-model="data.ort"
          label="Ort"
        />
      </v-col>
      <v-col
        cols="6"
      >
        <h3>Administratoren:</h3>
        {{ admins }}
      </v-col>
      <v-col
        cols="6"
      >
        <h3>Veranstalter:</h3>
        {{ organizers }}
      </v-col>
    </v-row>
  </base-material-dialog>
</template>

<script>
import { useAuth } from '@/plugins/auth'
import { useEditDialog } from '@/plugins/editdialog'

export default {
  name: 'EditOrganizer',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      default: null
    }
  },

  setup (props, context) {
    return {
      ...useAuth(context),
      ...useEditDialog(props, context, 'Organizer(id: $id) { _id name plz ort admins { _id givenName familyName } organizers { _id givenName familyName } }', ['name', 'plz', 'ort'])
    }
  },

  computed: {
    admins () {
      return this.data.admins?.map(e => e.familyName + ', ' + e.givenName)?.join('<br>')
    },
    organizers () {
      return this.data.organizers?.map(e => e.familyName + ', ' + e.givenName)?.join('<br>')
    }
  },

  methods: {
    update () {
      return {
        mutation: `mutation($id: UUID!, $name: String, $plz: Int, $ort: String) {
          OrganizerUpdate(id: $id, name: $name, plz: $plz, ort: $ort) {
            _id name plz ort
          }
        }`,
        variables: {
          plz: parseInt(this.data.plz) || null
        }
      }
    },
    create () {
      return {
        mutation: `mutation($name: String!, $plz: Int, $ort: String) {
          OrganizerCreate(name: $name, plz: $plz, ort: $ort) {
            _id name plz ort
          }
        }`,
        variables: {
          plz: parseInt(this.data.plz) || null
        }
      }
    }
  }
}
</script>

<style lang="sass">
  $dialog-elevation: 0
</style>
