import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router'
import store from './plugins/store'
import vuetify from './plugins/vuetify'
import apolloProvider from './plugins/graphql'

import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-pro/css/all.css'

import './plugins/cookies'
import './plugins/compositionAPI'
import './plugins/base'

import './filter'

Vue.component('Draggable', () => import('vuedraggable'))

Vue.component('E403', () => import('./components/e403.vue'))
Vue.component('DateSelector', () => import('./components/DateSelector.vue'))
Vue.component('DateTimeSelector', () => import('./components/DateTimeSelector.vue'))
Vue.component('Confirm', () => import('./components/Confirm.vue'))

Vue.config.productionTip = false

store.state.sim = !!window.location.href.match(/school/)

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
