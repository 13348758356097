import { computed } from '@vue/composition-api'

export const useAuth = (context) => {
  const isMaster = computed(() => {
    return context.root.$store.getters.isMaster
  })

  const isAdmin = (id) => {
    return context.root.$store.getters.isAdmin(id)
  }

  const isOrga = (id) => {
    return context.root.$store.getters.isOrga(id)
  }

  return { isMaster, isAdmin, isOrga }
}
