<template>
  <v-container
    fluid
    tag="section"
  >
    <h1>{{ name }}</h1>
    <v-data-table
      :headers="ergebnis.headers"
      :items="ergebnisse"
      sort-by="time"
      :items-per-page="-1"
    >
      <template #item.time="{item}">
        {{ item.time | float2_0 }}s
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'

const timeslotquery = '_id time duration team { _id name } result { bonus runtime calctime results { _id teile bonus wechsel bonusteil fehler } }'
const query = `_id name date _organizer organizer { name } apparatus { _apparatus apparatus { name logo } elements bonus malus } timeslots { ${timeslotquery} }`

export default {
  name: 'Public',

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    Event: null,
    ApparatusFind: [],
    ergebnis: {
      headers: [
        {
          text: 'Platz',
          value: 'platz',
          sortable: false
        },
        {
          text: 'Mannschaft',
          value: 'team',
          sortable: false
        },
        {
          text: 'Zeit',
          value: 'time',
          sortable: false
        }
      ]
    }
  }),

  computed: {
    name () {
      return this.Event?.name
    },
    ergebnisse () {
      return this.Event?.timeslots?.reduce((acc, curr) => {
        if (curr.team && curr.result) {
          acc.push({ team: curr.team.name, time: curr.result.calctime })
        }
        return acc
      }, []).sort((a, b) => a.time < b.time ? -1 : 1).map((m, i) => ({ ...m, platz: i + 1 }))
    }
  },

  apollo: {
    Event: {
      query: gql`query($event: UUID!) { Event(id: $event) { ${query} } }`,
      variables () {
        return {
          event: this.id
        }
      },
      subscribeToMore: {
        document: gql`subscription($id: UUID!) { EventUpdated(id: $id) { ${query} } }`,
        variables () {
          return {
            id: this.id
          }
        }
      }
    },
    ApparatusFind: {
      query: gql`query { ApparatusFind { _id name }}`
    }
  }
}
</script>

<style scoped>

</style>
