import Vue from 'vue'
import moment from 'moment'
moment.locale('de')

Vue.filter('ts2date', value => {
  if (value) {
    return (new Date(parseInt(value))).toISOString()
  }
})

Vue.filter('date', value => {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY')
  }
})

Vue.filter('dateformat', (value, format) => {
  if (!format) format = 'DD.MM.YYYY HH:mm'

  if (value) {
    return moment(String(value)).format(format)
  }
})

Vue.filter('ms2time', (value) => {
  if (!value) value = 0
  value = value / 1000

  const min = Math.floor(value / 60)
  const s = value - 60 * min

  let ret = ''

  if (min) {
    ret += min + 'm '
    if (s < 10) ret += '0'
  }
  ret += s.toFixed(2) + 's'

  return ret
})

Vue.filter('isodate', value => {
  if (value) {
    return moment(String(value)).format()
  }
  return null
})

Vue.filter('float2', function (value) {
  const float = parseFloat(value)
  if (isNaN(float) || float === 0) return ''
  return float.toFixed(2)
})

Vue.filter('float2force', function (value) {
  const float = parseFloat(value)
  if (isNaN(float)) return ''
  return float.toFixed(2)
})

Vue.filter('float2_0', function (value) {
  const float = parseFloat(value)
  if (isNaN(float) || float === 0) return '0.00'
  return float.toFixed(2)
})

Vue.filter('float1', function (value) {
  const float = parseFloat(value)
  if (isNaN(float) || float === 0) return ''
  return float.toFixed(1)
})

Vue.filter('int', function (value) {
  const int = parseInt(value)
  if (isNaN(int) || int === 0) return ''
  return int.toString()
})

Vue.filter('int_0', function (value) {
  const int = parseInt(value)
  if (isNaN(int) || int === 0) return '0'
  return int.toString()
})

Vue.filter('nl2br', function (value) {
  return value?.replace(/\n/g, '<br/>')
})
