<template>
  <v-container
    id="dashboard-core-footer"
    class="v-footer"
  >
    <v-row
      align="center"
      no-gutters
    >
      <v-col
        v-for="(link, i) in links"
        :key="i"
        class="text-center mb-sm-0 mb-5"
        cols="auto"
      >
        <a
          :href="'/#'+link.to"
          class="mr-0 grey--text text--darken-3"
          rel="noopener"
          v-text="link.text"
        />
      </v-col>

      <v-spacer class="hidden-sm-and-down" />

      <v-col
        cols="12"
        md="auto"
      >
        <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
          &copy; 2021 IT Kimmig
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DashboardCoreFooter',

  data: () => ({
    links: [
      {
        to: '/',
        text: 'Startseite'
      },
      {
        to: '/pages/impress',
        text: 'Impressum'
      },
      {
        to: '/pages/privacy',
        text: 'Datenschutz'
      }
    ]
  })
}
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase

  .v-footer
    a
      padding-left: 0px
</style>
