<template>
  <e403 v-if="!isMaster" />
  <v-container
    v-else
    fluid
    tag="section"
  >
    <v-card
      flat
    >
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="open(null)"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>

      <v-col>
        <v-text-field
          v-model="filter"
          label="Filter"
        />
      </v-col>

      <v-data-table
        :headers="headers"
        :items="ApparatusFind"
        sort-by="name"
        :items-per-page="15"
        mobile-breakpoint="0"
        :search="filter"
        @click:row="open"
      >
        <template #item.logo="{item}">
          <img
            v-if="item.logo"
            height="64"
            :src="item.logo"
          >
          <v-avatar
            v-else
            color="grey lighten-2"
            size="32"
          >
            {{ item.name.substr(0,2) }}
          </v-avatar>
        </template>
      </v-data-table>
    </v-card>
    <edit-apparatus
      :id="dialog.id"
      v-model="dialog.open"
    />
  </v-container>
</template>

<script>
import { useAuth } from '@/plugins/auth'
import gql from 'graphql-tag'
import { updateQuery, deleteQuery } from '@/plugins/graphql'

export default {
  name: 'People',

  components: {
    EditApparatus: () => import('./dialogs/EditApparatus')
  },

  setup (props, context) {
    return {
      ...useAuth(context)
    }
  },

  data: () => ({
    ApparatusFind: [],
    headers: [
      {
        text: '',
        value: 'logo',
        sortable: false,
        width: 100
      },
      {
        text: 'Name',
        value: 'name',
        sortable: true
      }
    ],
    dialog: {
      open: false,
      id: null
    },
    filter: ''
  }),

  apollo: {
    ApparatusFind: {
      query: gql`query { ApparatusFind { _id name logo } }`,
      subscribeToMore: {
        document: gql`subscription { ApparatusUpdated { _id name logo } }`,
        updateQuery: updateQuery('ApparatusFind', 'ApparatusUpdated')
      }
    },
    $subscribe: {
      PersonDeleted: {
        query: gql`subscription { ApparatusDeleted }`,
        result (id) {
          deleteQuery('ApparatusFind', 'ApparatusDeleted', this.ApparatusFind, id)
        }
      }
    }
  },

  methods: {
    open (item) {
      this.dialog.open = true
      this.dialog.id = item?._id
    }
  }
}
</script>

<style scoped>
</style>
