var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-item',{attrs:{"item":{
      icon:'mdi-view-dashboard',
      title: 'Startseite',
      to: '/'
    }}}),(_vm.isMaster)?_c('base-item-group',{attrs:{"item":{
      group: '/management',
      icon: 'fa-user-crown',
      title: 'Administration',
      children: [
        {
          title: 'Geräte',
          to: 'apparatus',
          icon: 'fa-dumbbell',
        },
        {
          title: 'Veranstalter',
          to: 'organizer',
          icon: 'mdi-account-supervisor-circle',
        },
        {
          title: 'Personen',
          to: 'person',
          icon: 'mdi-account-edit',
        }
      ]
    }}}):_vm._e(),_vm._l((_vm.adminOf),function(a){return _c('base-item',{key:'admin-'+a._id,attrs:{"item":{
      // group: '/admin',
      icon: 'fa-user-crown',
      title: 'Verwaltung',
      subtitle: a.name,
      to: '/admin/' + a._id
      /* children: [
        {
          title: 'Nutzer verwalten',
          icon: 'fa-users',
          to: 'person'
        }
      ] */
    }}})}),_vm._l((_vm.organizerOf),function(o){return _c('base-item',{key:'orga-'+o._id,attrs:{"item":{
      // group: '/orga',
      icon: 'fa-calendar-edit',
      title: 'Organisation',
      subtitle: o.name,
      to: '/orga/' + o._id
      /* children: [
        {
          title: 'Events verwalten',
          icon: 'fa-calendar-day',
          to: 'event/' + o._id
        }
      ] */
    }}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }