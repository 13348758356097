<template>
  <v-dialog
    v-model="show"
    persistent
    scrollable
    styles="overflow:visible;"
    content-class="v-dialog--material"
    @keydown.esc="$emit('esc')"
  >
    <base-material-card
      :avatar="avatar"
      :color="color"
      :icon="icon"
      :image="image"
      :text="text"
      :title="title"
      :sub-title="subTitle"
    >
      <v-card-text>
        <slot />
      </v-card-text>
      <template #actions>
        <v-spacer />
        <template v-for="(a,i) in actions">
          <v-btn
            v-if="a === 'ok'"
            :key="`${a}-${i}`"
            @click="$emit(a)"
          >
            OK
          </v-btn>
          <v-btn
            v-if="a === 'cancel'"
            :key="`${a}-${i}`"
            @click="$emit('close')"
          >
            Abbrechen
          </v-btn>
          <v-btn
            v-if="a === 'close'"
            :key="`${a}-${i}`"
            @click="$emit('close')"
          >
            Schließen
          </v-btn>
          <v-btn
            v-if="a === 'save'"
            :key="`${a}-${i}`"
            @click="$emit(a)"
          >
            Speichern
          </v-btn>
          <v-btn
            v-if="a === 'del'"
            :key="`${a}-${i}`"
            @click="$emit(a)"
          >
            Löschen
          </v-btn>
        </template>
      </template>
    </base-material-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MaterialCard',

  inheritAttrs: false,

  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    avatar: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'success'
    },
    hoverReveal: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: undefined
    },
    image: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    actions: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    classes () {
      return {
        'v-card--material--has-heading': this.hasHeading,
        'v-card--material--hover-reveal': this.hoverReveal
      }
    },
    hasHeading () {
      return Boolean(this.$slots.heading || this.title || this.icon)
    },
    hasAltHeading () {
      return Boolean(this.$slots.heading || (this.title && this.icon))
    }
  }
}
</script>

<style lang="sass">
  .v-card--material
    &__avatar
      position: relative
      top: -64px
      margin-bottom: -32px

    &__heading
      position: relative
      top: -40px
      transition: .3s ease
      z-index: 1

    &.v-card--material--hover-reveal:hover
      .v-card--material__heading
        transform: translateY(-40px)

  .v-dialog--material
    overflow-y: visible !important
</style>
