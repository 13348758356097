<template>
  <base-material-dialog
    :value="value"
    icon="mdi-home"
    title="Person bearbeiten"
    :sub-title="id ? id : 'NEU'"
    color="rgb(255, 4, 29)"
    :actions="[doc && isMaster ? 'del' : '', isMaster ? 'save' : '', 'cancel']"
    @del="del"
    @save="save"
    @close="close"
    @esc="close"
  >
    <v-row v-if="!isMaster">
      Kein Zugriff! {{ isMaster }}
    </v-row>
    <v-row v-else>
      <v-col
        cols="6"
      >
        <v-text-field
          v-model="data.givenName"
          label="Vorname"
        />
      </v-col>
      <v-col
        cols="6"
      >
        <v-text-field
          v-model="data.familyName"
          label="Nachname"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-text-field
          v-model="data.email"
          label="E-Mail-Adresse"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-checkbox
          v-model="data.master"
          label="System-Administrator"
        />
      </v-col>
      <v-col
        cols="6"
      >
        <h3>Administrator von:</h3>
        {{ adminOf }}
      </v-col>
      <v-col
        cols="6"
      >
        <h3>Veranstalter von:</h3>
        {{ organizerOf }}
      </v-col>
    </v-row>
  </base-material-dialog>
</template>

<script>
import { useAuth } from '@/plugins/auth'
import { useEditDialog } from '@/plugins/editdialog'

export default {
  name: 'EditPerson',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      default: null
    }
  },

  setup (props, context) {
    return {
      ...useAuth(context),
      ...useEditDialog(props, context, 'Person(id: $id) { _id givenName familyName email master adminOf { name plz ort } organizerOf { name plz ort } }', ['givenName', 'familyName', 'email', 'master'])
    }
  },

  computed: {
    adminOf () {
      return this.data.adminOf?.map(e => e.name + ' (' + e.plz + ' ' + e.ort + ')')?.join('<br>')
    },
    organizerOf () {
      return this.data.organizerOf?.map(e => e.name + ' (' + e.plz + ' ' + e.ort + ')')?.join('<br>')
    }
  },

  methods: {
    update () {
      return {
        mutation: `mutation($id: UUID!, $givenName: String, $familyName: String, $email: EmailAddress, $master: Boolean) {
          PersonUpdate(id: $id, givenName: $givenName, familyName: $familyName, email: $email, master: $master) {
            _id givenName familyName email master
          }
        }`,
        variables: {
          master: !!this.data.master
        }
      }
    },
    create () {
      return {
        mutation: `mutation($givenName: String!, $familyName: String!, $email: EmailAddress!, $master: Boolean) {
          PersonCreate(givenName: $givenName, familyName: $familyName, email: $email, master: $master) {
            _id givenName familyName email master
          }
        }`,
        variables: {
          master: !!this.data.master
        }
      }
    }
  }
}
</script>

<style lang="sass">
  $dialog-elevation: 0
</style>
