<template>
  <sim v-if="$store.state.sim" />
  <fim v-else />
</template>

<script>
export default {
  name: 'start',

  components: {
    sim: () => import('./start_sim'),
    fim: () => import('./start_4im')
  }
}

</script>

<style scoped>

</style>
