import { render, staticRenderFns } from "./EditDialogDate.vue?vue&type=template&id=47515c31&scoped=true&"
import script from "./EditDialogDate.vue?vue&type=script&lang=js&"
export * from "./EditDialogDate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47515c31",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VEditDialog } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VEditDialog})
