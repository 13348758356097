import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    profile: null,
    messages: [],
    drawer: true,
    snackbar: {
      visible: false,
      text: ''
    },
    sim: true
  },
  mutations: {
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    OPEN_SNACKBAR (state, text) {
      state.snackbar.text = text
      state.snackbar.visible = true
    },
    CLOSE_SNACKBAR (state) {
      state.snackbar.visible = false
    },
    SET_PROFILE (state, profile) {
      Vue.set(state, 'profile', profile)
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    profile: (state) => state.profile || {},
    isMaster: (state) => !!state.profile?.master,
    isLogin: (state) => !!state.profile?.token,
    isAdmin: (state) => (id) => !!state.profile?.adminOf?.find(a => a._id === id),
    isOrga: (state) => (id) => !!state.profile?.adminOf?.find(a => a._id === id) || !!state.profile?.organizerOf?.find(a => a._id === id)
  }
})
